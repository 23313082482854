const forms = selector => {
  // handle either an element or a query selector
  const elements =
    typeof selector === "string"
      ? document.querySelectorAll(selector)
      : [selector];
  return elements;
};

// Takes a form and looks at all the inputs within it to try and construct
// a Javascript object with the keys being the 'name' attribute of each input
// and the values being the input's values.
//
// Slightly complicated by the fact that we often have multiple checkbox inputs
// with the same name, for example 'style[]' for style filters. In this case
// the key would be 'style[]' and the value would be an array of the selected
// values, ie
//
// { "style[]": ["monogram", "classic"]
//
const parse = selector => {
  const ret = {};

  forms(selector).forEach(form => {
    const formData = new FormData(form);

    Array.from(formData.entries()).forEach(([key, value]) => {
      if (key.endsWith("[]")) {
        ret[key] = ret[key] ? [...ret[key], value] : [value];
      } else {
        ret[key] = value;
      }
    });
  });

  return ret;
};

const serialise = data => {
  return Array.from(Object.entries(data))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(v => `${key}=${encodeURIComponent(v)}`).join("&");
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");
};

export { parse, serialise };
