import { parameterize } from "../utils/rails";
import { serialise } from "../utils/form";

class ApplyFilters {
  constructor(formData, baseUrl, addToPath) {
    this.formData = formData;
    const { sort, ...filters } = formData;
    this.sort = sort;
    this.filters = filters;
    this.baseUrl = baseUrl;
    this.addToPath = addToPath;
  }

  init() {
    if (this.sort === "favourite") {
      delete this.formData.sort;
    }
  }

  get categorisedFilters() {
    return {
      styles: this.filters["styles[]"],
      colours: this.filters["colour[]"],
      designers: this.filters["designer[]"],
      products: this.filters["product[]"],
      photo: this.filters["photo[]"]
    };
  }

  get allAppliedFilterValues() {
    return Object.values(this.filters).flat().filter(Boolean);
  }

  get allAppliedCleanFilterValues() {
    return Object.values(this.categorisedFilters).flat().filter(Boolean);
  }

  get singlyAppliedCleanFilterValue() {
    if (this.allAppliedCleanFilterValues.length !== 1) {
      return null;
    }

    return this.allAppliedCleanFilterValues[0];
  }

  get photoApplied() {
    return !!this.filters["photo[]"];
  }

  get url() {
    // base level url - we just tack on to url if there is only one filter applied
    // and we are adding to path
    if (
      this.addToPath &&
      this.singlyAppliedCleanFilterValue &&
      this.allAppliedFilterValues.length === 1
    ) {
      if (this.photoApplied) {
        return `${this.baseUrl}photo`;
      }

      return `${this.baseUrl}${parameterize(
        this.singlyAppliedCleanFilterValue
      )}`;
    }

    // if there are no filters applied, we just return the base url with serialised query
    return `${this.baseUrl}?${serialise(this.formData)}`;
  }

  get shouldAddSortParam() {
    return (
      this.sort && this.sort !== "favourite" && !this.url.includes(this.sort)
    );
  }

  get urlWithSort() {
    if (!this.shouldAddSortParam) {
      return this.url;
    }

    if (this.url.includes("?")) {
      return `${this.url}&sort=${this.sort}`;
    }

    return `${this.url}?sort=${this.sort}`;
  }

  get cleanUrl() {
    // remove the ? if there is no query or filters
    if (this.urlWithSort.charAt(this.urlWithSort.length - 1) === "?") {
      return this.urlWithSort.slice(0, -1);
    }

    return this.urlWithSort;
  }

  get state() {
    return {
      attributes: serialise(this.formData) || " "
    };
  }

  call() {
    this.init();
    window.history.pushState(this.state, document.title, this.cleanUrl);
    const popStateEvent = new PopStateEvent("popstate", {
      state: this.state
    });
    dispatchEvent(popStateEvent);
  }
}

const applyFilters = (formData, baseUrl, addToPath) => {
  const filters = new ApplyFilters(formData, baseUrl, addToPath);
  filters.call();
};
export default applyFilters;
